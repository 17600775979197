<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col md="8">
        <v-card flat style="background-color: transparent !important">
          <h5 class="text-h5">Página não encontrada!</h5>

          <v-divider class="my-3"></v-divider>

          <div class="grey--text mb-5">A página que você procura não foi encontrada :(</div>

          <v-btn style="float: right" depressed color="primary" @click="$router.go(-1)">
            <v-icon left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped></style>
